export const colors = {
  "#44aa99": "video_in_retail",
  "#117733": "food_waste",
  "#aa4499": "ecommerce_loss",
  "#882255": "inventory_accuracy",
  "#999999": "on_shelf_availability",
  "#999933": "self_checkout",
  "#cc6677": "rfid_in_retail",
  "#ddcc77": "retail_loss_safety_and_security",
}
