import { gql } from "@apollo/client"

export const GET_EVENTS_QUERY = gql`
  query FetchEvents($startDateAfter: DateTime!, $startDateBefore: DateTime!) {
    allEvents(
      where: {
        start_date_after: $startDateAfter
        start_date_before: $startDateBefore
      }
    ) {
      edges {
        node {
          title
          start_date
          end_date
          organiser
          category {
            ... on Category {
              name
              color
            }
          }
          _meta {
            uid
          }
        }
      }
    }
  }
`
