import React from "react"
import { Calendar } from "../components/Calendar/calendar"
import Layout from "../components/Layout"
import { MainBackground } from "../components/MainBackground"

const CalendarPage = ({ location }) => {
  return (
    <Layout location={location}>
      <MainBackground type="blue" container>
        <Calendar />
      </MainBackground>
    </Layout>
  )
}

export default CalendarPage
