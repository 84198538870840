export const objFormatter = data => {
  const { _meta: meta } = data
  return {
    title: data?.title[0]?.text,
    organiser: data?.organiser,
    city: data?.city,
    startDate: data?.start_date,
    endDate: data?.end_date,
    category: data?.category,
    meta,
  }
}
